import React from 'react'
import { Link } from "react-router-dom";
import './Navbar.css'
import logo from '../assets/Csilogo.png'

const Navbar = () => {
    return (
        <>

            <nav class="navbar navbar-expand-lg bg-body-tertiary shadow">
                <div class="container-fluid">
                    <Link to="/"><a class="navbar-brand" href="#"><img src={logo} alt='...' height={60} /></a></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link to="/"><a class="nav-link active" aria-current="page" href="#">Home</a></Link>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/krishi"><a class="dropdown-item" href="#">CS-KRISHI</a></Link></li>
                                    <li ><Link to="/mamba"><a class="dropdown-item" href="#" >CS-MAMBA</a></Link></li>
                                    {/* <li><Link to="/bhim"><a class="dropdown-item" href="#">CS-Bhim</a></Link></li> */}
                                    {/* <li><hr class="dropdown-divider"/></li>
                                    <a class="dropdown-item" href="#">Comig Soon...</a> */}
                                    <li><Link to="/pride"><a class="dropdown-item" href="#">CS-PRIDE</a></Link></li>
                                    <li><Link to="/bee"><a class="dropdown-item" href="#">CS-BEE</a></Link></li>
                                    <li><Link to="/vtol"><a class="dropdown-item" href="#">CS-VTOL</a></Link></li>
                                    <li><Link to="/pavanputra"><a class="dropdown-item" href="#">CS-PAVANPUTRA</a></Link></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <Link to="/service"><a class="nav-link" href="#">Services</a></Link>
                            </li>
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Initiative
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/csiacademy"><a class="dropdown-item" href="#">CSI-Academy</a></Link></li>
                                    <li><Link to="/sparkathon"><a class="dropdown-item" href="#">Sparkathon</a></Link></li>
                                </ul>
                            </li> */}
                            {/* <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    CSA
                                </a>
                                <ul class="dropdown-menu">
                                    <li><Link to="/csiacademy"><a class="dropdown-item" href="#">DGCA RPTO</a></Link></li>
                                    <li><Link to="/course1"><a class="dropdown-item" href="#">6 Month Course</a></Link></li>
                                    <li><Link to="/course2"><a class="dropdown-item" href="#">21 Day Course</a></Link></li>
                                </ul>
                            </li> */}
                            <li class="nav-item">
                                <Link to="/csiacademy"><a class="nav-link" href="#">RPTO</a></Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/sparkathon"><a class="nav-link" href="#">Sparkathon</a></Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/about"><a class="nav-link" href="#">About</a></Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/gallary"><a class="nav-link" href="#">Gallery</a></Link>
                            </li>
                            <li class="nav-item">
                                <Link to="/contactus"><a class="nav-link" href="#">Contact Us</a></Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar